import clsx from "clsx"
import React from "react"
import { FaBars } from "react-icons/fa"

type HamburgerProps = {
  setOpen: () => void
}

const Hamburger: React.FC<HamburgerProps> = ({ setOpen }) => {
  return (
    <button
      className="btn btn-ghost relative focus:outline-none"
      onClick={setOpen}
    >
      <span className="sr-only">Open main menu</span>
      <FaBars size={24} />
    </button>
  )
}

export default Hamburger
