import Container from "@modules/mobile-menu/components/container"
import MainMenu from "@modules/mobile-menu/components/main-menu"
import { useMobileMenu } from "@lib/context/mobile-menu-context"

const MobileMenu = () => {
  const {
    screen: [currentScreen],
  } = useMobileMenu()

  return (
    <Container>
      <div className="flex flex-col flex-1">
        {(() => {
          switch (currentScreen) {
            default:
              return <MainMenu />
          }
        })()}
      </div>
    </Container>
  )
}

export default MobileMenu
