"use client"

import { useEffect, useState } from "react"
import Image from "next/image"
import Link from "next/link"
import clsx from "clsx"
import { useCart } from "medusa-react"
import { usePathname } from "next/navigation"
import { BiCart, BiUser } from "react-icons/bi"

import logo from "@assets/images/logo-white.png"
import Hamburger from "@modules/common/components/hamburger"
import MobileMenu from "@modules/mobile-menu/templates"
import { useMobileMenu } from "@lib/context/mobile-menu-context"

const menuItems = [
  {
    label: "Hem",
    href: "/",
  },
  {
    label: "Shop",
    href: "/shop",
  },
  {
    label: "Om oss",
    href: "/om-oss",
  },
  {
    label: "Galleri",
    href: "/galleri",
  },
  {
    label: "Storleksguide",
    href: "/storleksguide",
  },
  {
    label: "FAQ",
    href: "/fragor-och-svar",
  },
  {
    label: "Kontakt",
    href: "/kontakt",
  },
]

const Nav = () => {
  const { totalItems, cart } = useCart()
  const pathname = usePathname()
  const [isHome, setIsHome] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const serviceFee = cart.items.find(
    (item) => item.variant_id === process.env.NEXT_PUBLIC_SERVICE_FEE_VARIANT_ID
  )

  //useEffect that detects if window is scrolled > 5px on the Y axis
  useEffect(() => {
    if (isHome) {
      const detectScrollY = () => {
        if (window.scrollY > 5) {
          setIsScrolled(true)
        } else {
          setIsScrolled(false)
        }
      }

      window.addEventListener("scroll", detectScrollY)

      return () => {
        window.removeEventListener("scroll", detectScrollY)
      }
    }
  }, [isHome])

  useEffect(() => {
    pathname === "/" ? setIsHome(true) : setIsHome(false)
  }, [pathname])

  const { toggle } = useMobileMenu()

  return (
    <div
      className={clsx("sticky top-0 inset-x-0 z-50 group", {
        // "!fixed": isHome,
      })}
    >
      {/* <div className="w-full uppercase bg-primary text-base-100 p-2 text-center text-xs">
        Två veckors leveranstid!
      </div> */}
      <header
        className={clsx(
          "relative transition-colors bg-secondary duration-200 lg:min-h-[89.5px]",
          {
            // "!bg-accent": !isHome || isScrolled,
          }
        )}
      >
        <nav
          className={clsx(
            "text-base-100 flex items-center justify-between w-full h-full text-sm transition-colors duration-200 content-container px-2 xl:px-0",
            {
              // "text-white group-hover:text-gray-900": isHome && !isScrolled,
            }
          )}
        >
          <div className="flex-1 basis-0 h-full flex items-center">
            <div className="block small:hidden">
              <Hamburger setOpen={toggle} />
            </div>
            <Link href="/" className="py-4">
              <Image
                src={logo}
                height={57.5}
                width={32}
                alt="Vasastudent logga"
                loading="eager"
              />
            </Link>
          </div>

          <div className="flex-auto items-center h-full hidden small:flex">
            <ul className="flex items-center gap-8 uppercase">
              {menuItems.map((mi) => {
                return (
                  <Link
                    className={clsx(
                      "cursor-pointer text-md hover:text-primary hover:border-b-2 hover:font-semibold border-primary py-4",
                      {
                        "text-primary border-b-2 font-semibold":
                          pathname === mi.href,
                      }
                    )}
                    key={mi.label}
                    href={mi.href}
                  >
                    {mi.label}
                  </Link>
                )
              })}
            </ul>
          </div>

          <div className="flex items-center gap-x-0 h-full flex-1 basis-0 justify-end">
            {/* <div className="flex items-center gap-x-4 h-full">
              <Link
                href="https://instagram.com/vasastudent"
                title="Instagram konto"
                className="p-2 lg:btn btn-instagram rounded-md font-heading text-white"
                target="_blank"
              >
                <BiLogoInstagram color="white" size={24} />{" "}
                <span className="hidden lg:block text-white">Instagram</span>
              </Link>
            </div> */}
            <Link href="/designer" className="btn btn-primary btn-md mr-4">
              Till design
            </Link>

            <div className="hidden small:flex items-center h-full">
              <Link className="px-2 py-4" href="/konto" title="Konto">
                <BiUser size={24} />
              </Link>
            </div>

            <div className="flex items-center gap-x-6 h-full">
              <Link
                href="/kundvagn"
                className="flex items-center gap-1 px-2 py-4 h-full"
                title="Kundvagn"
              >
                <BiCart size={28} /> ({serviceFee ? totalItems - 1 : totalItems}
                )
              </Link>
            </div>
          </div>
        </nav>
        <MobileMenu />
      </header>
      {/* <BackendStatus /> */}
    </div>
  )
}

export default Nav
