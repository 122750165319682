"use client"

import Link from "next/link"
import Image from "next/image"
import { FaFacebookF, FaInstagram } from "react-icons/fa"
import { BiLogoPinterestAlt, BiLogoTiktok } from "react-icons/bi"
import { SiTrustpilot, SiYoutube } from "react-icons/si"

import cards from "@assets/images/cards.jpg"

const FooterNav = () => {
  return (
    <>
      <div className="content-container py-12 px-4 xl:px-0 text-white">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-12 items-start justify-between">
          <div className="md:max-w-xs">
            <Link href="/" className="text-xl-semi uppercase">
              <Image
                src="/images/logo_export/logo-vertical-512-white.png"
                alt="Vasastudent"
                height={215 / 2}
                width={512 / 2}
                quality={100}
              />
            </Link>
            <p className="leading-8 mt-4">
              Vasastudent är en hängiven leverantör av studentmössor, som hedrar
              den svenska traditionen med högkvalitativt hantverk.
            </p>
          </div>
          <div className="flex flex-col gap-y-2">
            <p className="text-2xl lg:text-3xl font-bold text-primary font-heading tracking-widest">
              Sidor
            </p>
            <ul className="grid grid-cols-1 gap-y-1">
              <li>
                <Link
                  className="cursor-pointer text-md hover:text-primary hover:border-b-2 border-primary"
                  href="/kopa-studentmossa"
                  title="Köpa studentmössa"
                >
                  Köpa studentmössa
                </Link>
              </li>
              <li>
                <Link
                  className="cursor-pointer text-md hover:text-primary hover:border-b-2 border-primary"
                  href="/studentmossa-program-farger"
                  title="Studentmössa program och färger"
                >
                  Program & Färger
                </Link>
              </li>
              <li>
                <Link
                  className="cursor-pointer text-md hover:text-primary hover:border-b-2 border-primary"
                  href="/fragor-och-svar"
                >
                  FAQ&apos;s
                </Link>
              </li>
              <li>
                <Link
                  className="cursor-pointer text-md hover:text-primary hover:border-b-2 border-primary"
                  href="/policy"
                >
                  Integritetspolicy
                </Link>
              </li>
              <li>
                <Link
                  className="cursor-pointer text-md hover:text-primary hover:border-b-2 border-primary"
                  href="/villkor"
                >
                  Villkor
                </Link>
              </li>
              <li>
                <Link
                  className="cursor-pointer text-md hover:text-primary hover:border-b-2 border-primary"
                  href="/kontakt"
                >
                  Kontakt
                </Link>
              </li>
            </ul>
            <div className="mt-4 hidden lg:block">
              <Link
                className="cursor-pointer"
                rel="external"
                target="_blank"
                href="https://www.klarna.com/se/hur-funkar-det/"
              >
                <Image
                  src={cards}
                  width={300}
                  height={50}
                  alt="Accepted Cards"
                />
              </Link>
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <p className="text-2xl lg:text-3xl font-bold text-primary font-heading tracking-widest">
              Socials
            </p>
            <div className="space-y-2">
              <Link
                rel="external"
                target="_blank"
                className="flex items-center gap-2 cursor-pointer text-md hover:text-primary"
                href="https://instagram.com/vasastudent"
              >
                <FaInstagram size={24} /> Instagram
              </Link>
              <Link
                rel="external"
                target="_blank"
                className="flex items-center gap-2 cursor-pointer text-md hover:text-primary"
                href="https://facebook.com/vasastudent"
              >
                <FaFacebookF size={24} /> Facebook
              </Link>
              <Link
                rel="external"
                target="_blank"
                className="flex items-center gap-2 cursor-pointer text-md hover:text-primary"
                href="https://www.tiktok.com/@vasastudent"
              >
                <BiLogoTiktok size={24} /> TikTok
              </Link>
              <Link
                rel="external"
                target="_blank"
                className="flex items-center gap-2 cursor-pointer text-md hover:text-primary"
                href="https://www.youtube.com/channel/UCBWb92t9PM9EwR-FRc0xVkA"
              >
                <SiYoutube size={24} /> Youtube
              </Link>
              <Link
                rel="external"
                target="_blank"
                className="flex items-center gap-2 cursor-pointer text-md hover:text-primary"
                href="https://pin.it/bAslqpg"
              >
                <BiLogoPinterestAlt size={24} /> Pinterest
              </Link>
              <Link
                rel="external"
                target="_blank"
                className="flex items-center gap-2 cursor-pointer text-md hover:text-primary"
                href="https://se.trustpilot.com/review/vasastudent.se"
              >
                <SiTrustpilot size={24} /> Trustpilot
              </Link>
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <p className="text-2xl lg:text-3xl font-bold text-primary font-heading tracking-widest">
              Kontakt
            </p>
            <div className="grid grid-cols-1 gap-y-4 text-sm">
              <div>
                <p className="font-bold uppercase">Adress:</p>
                <p className="">Kavallerivägen 4A,</p>
                <p className="">174 58 Sundbyberg</p>
              </div>
              <div>
                <p className="font-bold uppercase">Telefon:</p>
                <p className="">
                  <Link href="tel:+46735068002">(+46) 073-506 8002</Link>
                </p>
              </div>
              <div>
                <p className="font-bold uppercase">Email:</p>
                <p className="">
                  <Link href="mailto:support@vasastudent.se">
                    support@vasastudent.se
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4 block lg:hidden">
            <Link
              className="cursor-pointer"
              rel="external"
              target="_blank"
              href="https://www.klarna.com/se/hur-funkar-det/"
            >
              <Image src={cards} width={300} height={50} alt="Accepted Cards" />
            </Link>
          </div>
        </div>
      </div>
      <hr />
      <div className="content-container py-6 text-center text-white px-4 lg:px-0">
        <span className="text-base-regular">
          Copyright © {new Date().getFullYear()} Vasastudent. All rights
          reserved
        </span>
      </div>
    </>
  )
}

export default FooterNav
