import Image from "next/image"
import Link from "next/link"
import { useCollections, useMeCustomer } from "medusa-react"

import ChevronDown from "@modules/common/icons/chevron-down"
import X from "@modules/common/icons/x"
import { useMobileMenu } from "@lib/context/mobile-menu-context"

const menuItems = [
  {
    label: "Hem",
    href: "/",
  },
  {
    label: "Designa",
    href: "/designer",
  },
  {
    label: "Shop",
    href: "/shop",
  },
  {
    label: "Om oss",
    href: "/om-oss",
  },
  {
    label: "Galleri",
    href: "/galleri",
  },
  {
    label: "Storleksguide",
    href: "/storleksguide",
  },
  {
    label: "FAQ",
    href: "/fragor-och-svar",
  },
  {
    label: "Kontakt",
    href: "/kontakt",
  },
]

const MainMenu = () => {
  const { customer } = useMeCustomer()

  const {
    close,
    screen: [_],
  } = useMobileMenu()

  return (
    <div className="flex flex-col flex-1">
      <div className="flex items-center justify-between w-full bg-secondary border-b border-gray-200 p-2">
        <div className="flex-1 basis-0"></div>
        <Image
          src="/images/logo_export/logo-white.png"
          height={57.5}
          width={32}
          alt="Vasastudent"
          className="w-auto"
        />
        <div className="flex-1 basis-0 flex justify-end text-base-100">
          <button className="btn btn-ghost" onClick={close}>
            <X size={20} />
          </button>
        </div>
      </div>

      <div className="space-y-6 flex-1 flex flex-col justify-between p-6">
        <div className="flex flex-col flex-1 text-large-regular text-secondary">
          <ul className="flex flex-col gap-y-2">
            {menuItems.map((mi) => (
              <li key={JSON.stringify(mi)} className="bg-neutral p-4">
                <Link href={mi.href}>
                  <button
                    className="flex items-center justify-between w-full"
                    onClick={close}
                  >
                    <span className="sr-only">Till {mi.label}</span>
                    <span>{mi.label}</span>
                    <ChevronDown className="-rotate-90" />
                  </button>
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="flex flex-col">
          <div className="flex flex-col gap-y-8 text-small-regular">
            {!customer ? (
              <div className="flex flex-col gap-y-4">
                <span className="text-gray-700 uppercase">Konto</span>
                <Link href={`/konto/logga-in`} passHref>
                  <button
                    className="flex items-center justify-between border-b border-gray-200 py-2 w-full"
                    onClick={close}
                  >
                    <span className="sr-only">Gå till inloggningen</span>
                    <span className="normal-case">Logga in</span>
                    <ChevronDown className="-rotate-90" />
                  </button>
                </Link>
              </div>
            ) : (
              <div className="flex flex-col gap-y-4">
                <span className="text-gray-700 uppercase">Inloggad som</span>
                <Link href={`/konto`} passHref>
                  <button
                    className="flex items-center justify-between border-b border-gray-200 py-2 w-full"
                    onClick={close}
                  >
                    <span className="sr-only">Gå till konto</span>
                    <span className="normal-case">{customer.email}</span>
                    <ChevronDown className="-rotate-90" />
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainMenu
